import Vue from 'vue';

// home page
const HomePageSearch = () => import(/* webpackChunkName: 'home' */ '@/components/containers/HomePageSearch.vue');
const HomePageSearchV2 = () => import(/* webpackChunkName: 'home' */ '@/components/containers/HomePageSearch-v2.vue');
const AgentPageSearch = () => import(/* webpackChunkName: 'agent-search' */ '@/components/containers/AgentPageSearch.vue');
const AgentPageSearchDirectory = () => import(/* webpackChunkName: 'agent-search' */ '@/components/containers/AgentPageSearchDirectory.vue');
const PoiPageSearch = () => import(/* webpackChunkName: 'poi-search' */ '@/components/containers/PoiPageSearch.vue');
const HomePageListings = () => import(/* webpackChunkName: 'home' */ '@/components/containers/HomePageListings.vue');
const AiSearch = () => import(/* webpackChunkName: 'forms' */ '@/components/search/AiSearch.vue');

// srp
const SearchFilterBar = () => import(/* webpackChunkName: 'srp' */ '@/components/search/filters/SearchFilterBar.vue');
const SearchResultsStage = () => import(/* webpackChunkName: 'srp' */ '@/components/search/SearchResultsStage.vue');
const QuickSearchCards = () => import(/* webpackChunkName: 'srp' */ '@/components/search/QuickSearchCards.vue');
const NearbyCitiesCommunities = () => import(/* webpackChunkName: 'srp' */ '@/components/search/NearbyCitiesCommunities.vue');
const SearchChat = () => import(/* webpackChunkName: 'srp' */ '@/components/search/SearchChat.vue');

const SearchInterstitial = () => import(/* webpackChunkName: 'srp' */ '@/components/search/SearchInterstitial.vue');
const ListingCard = () => import(/* webpackChunkName: 'srp' */ '@/components/listing-details/ListingCard.vue');
const Listing3dTour = () => import(/* webpackChunkName: 'srp' */ '@/components/listing-details/Listing3DTour.vue');
const SaveSearch = () => import(/* webpackChunkName: 'srp' */ '@/components/search/SaveSearch.vue');
const MarketSearch = () => import(/* webpackChunkName: 'market' */ '@/components/search/MarketSearch.vue');
const RemoveBoundaryModal = () => import(/* webpackChunkName: 'srp' */ '@/components/search/RemoveBoundaryModal.vue');

// listing card
const ListingDetailsPhotoCarousel = () => import(/* webpackChunkName: 'listing' */ '@/components/listing-details/ListingDetailsPhotoCarousel.vue');
const FavoriteListing = () => import(/* webpackChunkName: 'listing' */ '@/components/listing-details/FavoriteListing.vue');
const ShareListing = () => import(/* webpackChunkName: 'listing' */ '@/components/listing-details/ShareListing.vue');
const ShareArticle = () => import(/* webpackChunkName: 'listing' */ '@/components/listing-details/ShareArticle.vue');
const StreetView = () => import(/* webpackChunkName: 'listing' */ '@/components/listing-details/StreetView.vue');

// maps
const SchoolMap = () => import(/* webpackChunkName: 'map' */ '@/components/listing-details/SchoolMap.vue');
const ListingDetailsMap = () => import(/* webpackChunkName: 'map' */ '@/components/listing-details/ListingDetailsMap.vue');
const HeatMap = () => import(/* webpackChunkName: 'map' */ '@/components/reporting/HeatMap.vue');
const HomeValueMap = () => import(/* webpackChunkName: 'map' */ '@/components/home-value/HomeValueMap.vue');
const HomeValueList = () => import(/* webpackChunkName: 'map' */ '@/components/home-value/HomeValueList.vue');
const HeatMapAgents = () => import(/* webpackChunkName: 'map' */ '@/components/reporting/HeatMapAgents.vue');
const MapContainer = () => import(/* webpackChunkName: 'map' */ '@/components/maps/MapContainer.vue');
const MapMarker = () => import(/* webpackChunkName: 'map' */ '@/components/maps/MapMarker.vue');

// global
const SearchAutocomplete = () => import(/* webpackChunkName: 'global' */ '@/components/search/SearchAutocomplete.vue');
const SearchAutocompleteV2 = () => import(/* webpackChunkName: 'global' */ '@/components/search/SearchAutocomplete-v2.vue');
const PublicRecordsSearchAutocomplete = () => import(/* webpackChunkName: 'global' */ '@/components/search/PublicRecordsSearchAutocomplete.vue');
const ModalWindow = () => import(/* webpackChunkName: 'global' */ '@/components/ModalWindow.vue');
const RequestForm = () => import(/* webpackChunkName: 'global' */ '@/components/forms/RequestForm.vue');
const SuccessNotification = () => import(/* webpackChunkName: 'global' */ '@/components/forms/SuccessNotification.vue');
const DownPaymentAssistanceForm = () => import(/* webpackChunkName: 'global' */ '@/components/forms/DownPaymentAssistanceForm.vue');
const SalesDemoFormPanel = () => import(/* webpackChunkName: 'global' */ '@/components/forms/sales-demo/SalesDemoFormPanel.vue');
const VeteransForm = () => import(/* webpackChunkName: 'forms' */ '@/components/forms/VeteransForm.vue');

// agent
const AgentCard = () => import(/* webpackChunkName: 'agent' */ '@/components/search/AgentCard.vue');
const MarketSearchAgentCards = () => import(/* webpackChunkName: 'agent' */ '@/components/search/market-search/MarketSearchAgentCards.vue');
const AgentCardCoBranded = () => import(/* webpackChunkName: 'agent-card-co-branded' */ '@/components/search/AgentCardCoBranded.vue');
const AgentCardWithMessage = () => import(/* webpackChunkName: 'agent-card-with-message' */ '@/components/search/AgentCardWithMessage.vue');
const AgentSetter = () => import(/* webpackChunkName: 'agent' */ '@/components/listing-details/AgentSetter.vue');
const AgentProfile = () => import(/* webpackChunkName: 'agent' */ '@/components/information-cards/AgentProfile.vue');
const ContactAgentForm = () => import(/* webpackChunkName: 'contact-form' */ '@/components/forms/ContactAgentForm.vue');
const MarketSearchContactForm = () => import(/* webpackChunkName: 'contact-form' */ '@/components/forms/MarketSearchContactForm.vue');

//auth
const UserManagementContainer = () => import(/* webpackChunkName: 'auth' */ '@/components/containers/UserManagementContainer.vue');
const Nudger = () => import(/* webpackChunkName: 'auth' */ '@/components/containers/Nudger.vue');
const AccountRecovery = () => import(/* webpackChunkName: 'auth' */ '@/components/forms/AccountRecovery.vue');
const PhoneConfirmForm = () => import(/* webpackChunkName: 'auth' */ '@/components/forms/PhoneConfirmForm.vue');
const ClientRegistration = () => import(/* webpackChunkName: 'auth' */ '@/components/forms/ClientRegistration.vue');
const PhoneConfirmContainer = () => import(/* webpackChunkName: 'auth' */ '@/components/containers/PhoneConfirmContainer.vue');

//user dashboard
const ScrubUserData = () => import(/* webpackChunkName: 'dashboard' */ '@/components/dashboard/ScrubUserData.vue');
const DashboardCardContainer = () => import(/* webpackChunkName: 'dashboard' */ '@/components/dashboard/DashboardCardContainer.vue');
const HistoryTable = () => import(/* webpackChunkName: 'dashboard' */ '@/components/dashboard/parts/HistoryTable.vue');

// forms
const HomeValueForm = () => import(/* webpackChunkName: 'form' */ '@/components/home-value/HomeValueForm.vue');
const HomeValueFormV2 = () => import(/* webpackChunkName: 'form' */ '@/components/home-value/HomeValueFormV2.vue');
const HomeValueSellForm = () => import(/* webpackChunkName: 'form' */ '@/components/home-value/HomeValueSellForm.vue');
const CashOfferMonthlyAlertCta = () => import(/* webpackChunkName: 'form' */ '@/components/home-value/CashOfferMonthlyAlertCTA.vue');
const BuySellForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/BuySellForm.vue');
const SellNowCashForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/SellNowCashForm.vue');
const SellNowForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/SellNowForm.vue');
const SellNowFormWithHomeValue = () => import(/* webpackChunkName: 'form' */ '@/components/forms/SellNowFormWithHomeValue.vue');
const SellNowFormWithHomeValueNew = () => import(/* webpackChunkName: 'form' */ '@/components/forms/SellNowFormWithHomeValueNew.vue');
const AgentProfileFormStepper = () => import(/* webpackChunkName: 'form' */ '@/components/forms/AgentProfileFormStepper.vue');
const MarketSearchFormStepper = () => import(/* webpackChunkName: 'form' */ '@/components/forms/MarketSearchFormStepper.vue');
const MortgageSearchFormStepper = () => import(/* webpackChunkName: 'form' */ '@/components/forms/MortgageSearchFormStepper.vue');
const MortgageSearchFormStepperTwo = () => import(/* webpackChunkName: 'form' */ '@/components/forms/MortgageSearchFormStepperTwo.vue');
const MortgageSearchFormStepperThree = () => import(/* webpackChunkName: 'form' */ '@/components/forms/MortgageSearchFormStepperThree.vue');
const AgentSearchAndClaimStepper = () => import(/* webpackChunkName: 'form' */ '@/components/forms/AgentSearchAndClaimStepper.vue');
const AgentVerificationStepper = () => import(/* webpackChunkName: 'form' */ '@/components/forms/AgentVerificationStepper.vue');
const AgentOnboardingStepper = () => import(/* webpackChunkName: 'form' */ '@/components/forms/AgentOnboardingStepper.vue');
const AgentProfileClaimStepper = () => import(/* webpackChunkName: 'form' */ '@/components/forms/AgentProfileClaimStepper.vue');
const SellingStepper = () => import(/* webpackChunkName: 'form' */ '@/components/forms/selling/SellingStepper.vue');
const NewRealCashForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/NewRealCashForm.vue');
const RealCashVerificationForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/RealCashVerificationForm.vue');
const LoginForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/LoginForm.vue');
const AgentLoginForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/AgentLoginForm.vue');
const AgentRecoveryForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/AgentRecoveryForm.vue');
const AgentRegistrationForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/AgentRegistrationForm.vue');
const UserDemographics = () => import(/* webpackChunkName: 'form' */ '@/components/forms/UserDemographics.vue');
const QbInquiryForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/QbInquiryForm.vue');
const MultiStepperScheduleDemoFrom = () => import(/* webpackChunkName: 'form' */ '@/components/forms/MultiStepperScheduleDemoFrom.vue');
const FormFreeQualifiedForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/FormFreeQualifiedForm.vue');
const MissingHomeValueForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/MissingHomeValueForm.vue');
const ReverseMortgageInquiryForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/ReverseMortgageInquiryForm.vue');
const UserAttributesForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/UserAttributesForm.vue');
const AgentDashboardContainer = () => import(/* webpackChunkName: 'form' */ '@/components/forms/AgentDashboardContainer.vue');
const SchoolForm = () => import(/* webpackChunkName: 'form' */ '@/components/school/SchoolForm.vue');
const InternalForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/InternalForm.vue');
const ContactLenderForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/ContactLenderForm.vue');
const BuyerExtendedContactForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/BuyerExtendedContactForm.vue');

//auth
const PhoneVerification = () => import(/* webpackChunkName: 'auth' */ '@/components/auth/PhoneVerification.vue');
const EmailVerification = () => import(/* webpackChunkName: 'auth' */ '@/components/auth/EmailVerification.vue');

//chart
const ListingDetailsChart = () => import(/* webpackChunkName: 'listing-details-chart' */ '@/components/charts/ListingDetailsChart.vue');
const MortgageRatesChart = () => import(/* webpackChunkName: 'mortgage-rates-chart' */ '@/components/charts/MortgageRatesChart.vue');
const MonthlyLineChart = () => import(/* webpackChunkName: 'monthly-line-chart' */ '@/components/charts/MonthlyLineChart.vue');
const HalfRoundedChart = () => import(/* webpackChunkName: 'my-value-chart' */ '@/components/charts/HalfRoundedChart.vue');
const SparkLine = () => import(/* webpackChunkName: 'spank-line-chart' */ '@/components/charts/SparkLine.vue');
const DonutChart = () => import(/* webpackChunkName: 'spank-line-chart' */ '@/components/charts/DonutChart.vue');
const LeadsAverageChart = () => import(/* webpackChunkName: 'leads-average-chart' */ '@/components/charts/LeadsAverageChart.vue');
// listing details
const ListingDetailsScheduleTour = () => import(/* webpackChunkName: 'listing-details' */ '@/components/listing-details/ListingDetailsScheduleTour.vue');
const ListingDetailsLoanCalculator = () => import(/* webpackChunkName: 'listing-details' */ '@/components/listing-details/ListingDetailsLoanCalculator.vue');
const EstimatedMortgage = () => import(/* webpackChunkName: 'listing-details' */ '@/components/listing-details/EstimatedMortgage.vue');
const RelatedPropertiesList = () => import(/* webpackChunkName: 'listing-details' */ '@/components/listing-details/RelatedPropertiesList.vue');
const NearbyCommunities = () => import(/* webpackChunkName: 'listing-details' */ '@/components/listing-details/NearbyCommunities.vue');
const YourCurrentMortgage = () => import(/* webpackChunkName: 'listing-details' */ '@/components/listing-details/YourCurrentMortgage.vue');
const GuildMortgageCompany = () => import(/* webpackChunkName: 'listing-details' */ '@/components/listing-details/GuildMortgageCompany.vue');
const InterestCard = () => import(/* webpackChunkName: 'home-value' */ '@/components/listing-details/InterestCard.vue');
const InterestCardV2 = () => import(/* webpackChunkName: 'home-value' */ '@/components/listing-details/InterestCardV2.vue');
const InterestAdviceCard = () => import(/* webpackChunkName: 'home-value' */ '@/components/listing-details/InterestAdviceCard.vue');
const InterestAdviceCardV2 = () => import(/* webpackChunkName: 'home-value' */ '@/components/listing-details/InterestAdviceCardv2.vue');
const ListingDownPaymentResources = () => import(/* webpackChunkName: 'listing-details' */ '@/components/listing-details/ListingDownPaymentResources.vue');
const DealScore = () => import(/* webpackChunkName: 'listing-details' */ '@/components/listing-details/DealScore.vue');
const ListingViewStats = () => import(/* webpackChunkName: 'listing-details' */ '@/components/listing-details/ListingViewStats.vue');

// homescount
const HsSearchAutoSuggestion = () => import(/* webpackChunkName: 'hs' */ '@/components/search/HsSearchAutoSuggestion.vue');
const SearchResultsAgentsGallery = () => import(/* webpackChunkName: 'hs' */ '@/components/search/SearchResultsAgentsGallery.vue');

// Initializers
const CookieCrumbler = () => import(/* webpackChunkName: 'initializers' */ '@/components/utilities/CookieCrumbler.vue');
const PropertyInitializer = () => import(/* webpackChunkName: 'initializers' */ '@/components/utilities/PropertyInitializer.vue');
const CarouselInitializer = () => import(/* webpackChunkName: 'initializers' */ '@/components/utilities/DCarouselInitializer.vue');
const UserProfile = () => import(/* webpackChunkName: 'initializers' */ '@/components/utilities/UserProfile.vue');
const LetterAvatar = () => import(/* webpackChunkName: 'initializers' */ '@/components/utilities/LetterAvatar.vue');
// const ComponentLoader = () => import(/* webpackChunkName: 'initializers' */ '@/components/utilities/ComponentLoader.vue');
const TablePaginator = () => import(/* webpackChunkName: 'paginator' */ '@/components/utilities/TablePaginator.vue');
const CityOfficePaginator = () => import(/* webpackChunkName: 'paginator' */ '@/components/utilities/CityOfficePaginator.vue');

//home value
const HomeValueMapWithDetails = () => import(/* webpackChunkName: 'home-value' */ '@/components/home-value/HomeValueMapWithDetails.vue');
const HomeValueEstimates = () => import(/* webpackChunkName: 'home-value' */ '@/components/home-value/HomeValueEstimates.vue');
const HomeValueSchoolsMap = () => import(/* webpackChunkName: 'home-value' */ '@/components/home-value/HomeValueSchoolsMap.vue');
const PoiPropertiesMap = () => import(/* webpackChunkName: 'poi-map' */ '@/components/home-value/PoiPropertiesMap.vue');
const AgentLocationsMap = () => import(/* webpackChunkName: 'agent-profile' */ '@/components/home-value/AgentLocationsMap.vue');

const AnimationContainer = () => import(/* webpackChunkName: 'animation' */ '@/components/containers/AnimationContainer.vue');
const ConfettiContainer = () => import(/* webpackChunkName: 'animation' */ '@/components/containers/ConfettiContainer.vue');

const NeighborhoodDatatable = () => import('@/components/reporting/NeighborhoodDatatable.vue');
const SellNowFormDetails = () => import('@/components/forms/SellNowFormDetails.vue');
const AgentReviewForm = () => import('@/components/forms/AgentReviewForm.vue');
const SellNowCashFormDetails = () => import('@/components/forms/SellNowCashFormDetails.vue');
const FinancialTools = () => import('@/components/containers/FinancialTools.vue');
const ExpandableContent = () => import('@/components/ExpandableContent.vue');
const LoginWithGoogle = () => import('@/components/LoginWithGoogle.vue');

const ReviewModalBody = () => import('@/components/information-cards/ReviewModalBody.vue');
const BrandingProfile = () => import('@/components/information-cards/BrandingProfile.vue');
const DarkModeContainer = () => import('@/components/containers/DarkModeContainer.vue');
const FooterContainer = () => import('@/components/containers/FooterContainer.vue');
const AdsInitializer = () => import('@/components/containers/AdsInitializer.vue');
const IdxDisclaimer = () => import('@/components/search/IdxDisclaimer.vue');
const CtaContainer = () => import('@/components/containers/CtaContainer.vue');
const StreetViewContainer = () => import('@/components/containers/StreetViewContainer.vue');
const TcpaContainer = () => import('@/components/containers/TcpaContainer.vue');
const TermsConsentDialog = () => import('@/components/TermsConsentDialog.vue');
const CarouselContainer = () => import('@/components/containers/CarouselContainer.vue');
const MarqueeSlider = () => import('@/components/containers/MarqueeSlider.vue');
const MonthlyAlertForm = () => import('@/components/forms/MonthlyAlertForm.vue');
const QrCodeGenerator = () => import('@/components/utilities/QrCodeGenerator.vue');
const BellhopContainer = () => import('@/components/containers/BellhopContainer.vue');
const MarketSearchOnLoadCta = () => import('@/components/cta/MarketSearchOnLoadCta.vue');
const AgentAccountUpgradeForm = () => import(/* webpackChunkName: 'form' */ '@/components/forms/partials/AgentAccountUpgradeForm.vue');
const SendgridTemplateExporter = () => import(/* webpackChunkName: 'sendgrid' */ '@/components/sendgrid/SendgridTemplateExporter.vue');
const Autocomplete = () => import(/* webpackChunkName: 'autocomplete' */ '@/components/autocomplete/Autocomplete.vue');
const ShareUrl = () => import(/* webpackChunkName: 'share-url' */ '@/components/ShareUrl.vue');
const VideoEmbed = () => import('@/components/containers/VideoEmbed.vue');
const Vimeo = () => import('@/components/containers/Vimeo.vue');
const YouTube = () => import('@/components/containers/Youtube.vue');

const components = {
    SearchAutocomplete,
    SearchAutocompleteV2,
    SearchFilterBar,
    SearchResultsStage,
    QuickSearchCards,
    NearbyCitiesCommunities,
    SaveSearch,
    SearchInterstitial,
    AgentCard,
    AgentCardCoBranded,
    RemoveBoundaryModal,
    IdxDisclaimer,
    ModalWindow,
    ListingCard,
    ListingDetailsChart,
    MortgageRatesChart,
    ListingDetailsPhotoCarousel,
    ListingDetailsMap,
    SchoolMap,
    MapContainer,
    ListingDetailsScheduleTour,
    ListingDetailsLoanCalculator,
    ShareListing,
    ShareArticle,
    AgentSetter,
    HomeValueMap,
    HomeValueList,
    HomeValueMapWithDetails,
    HomeValueEstimates,
    HomeValueForm,
    HomeValueSellForm,
    HomeValueSchoolsMap,
    HeatMap,
    HeatMapAgents,
    NeighborhoodDatatable,
    RelatedPropertiesList,
    NearbyCommunities,
    FavoriteListing,
    YourCurrentMortgage,
    GuildMortgageCompany,
    InterestCard,
    BuySellForm,
    SellNowForm,
    SellNowFormWithHomeValue,
    SellNowCashForm,
    SellNowFormDetails,
    SellNowCashFormDetails,
    RequestForm,
    UserDemographics,
    LoginForm,
    AccountRecovery,
    UserAttributesForm,
    PhoneConfirmForm,
    InternalForm,
    SuccessNotification,
    ContactAgentForm,
    MarketSearchContactForm,
    ContactLenderForm,
    ClientRegistration,
    PhoneConfirmContainer,
    HomePageSearch,
    HomePageSearchV2,
    AgentPageSearch,
    HomePageListings,
    FinancialTools,
    UserManagementContainer,
    FooterContainer,
    AdsInitializer,
    DarkModeContainer,
    ExpandableContent,
    ScrubUserData,
    DashboardCardContainer,
    HistoryTable,
    AgentProfile,
    ReviewModalBody,
    BrandingProfile,
    UserProfile,
    LetterAvatar,
    CookieCrumbler,
    PropertyInitializer,
    CarouselInitializer,
    SparkLine,
    MonthlyLineChart,
    SchoolForm,
    HsSearchAutoSuggestion,
    QbInquiryForm,
    // ComponentLoader, // no need to load now
    AgentLocationsMap,
    TablePaginator,
    CityOfficePaginator,
    Nudger,
    LoginWithGoogle,
    SearchResultsAgentsGallery,
    InterestAdviceCard,
    HalfRoundedChart,
    ListingDownPaymentResources,
    DownPaymentAssistanceForm,
    ReverseMortgageInquiryForm,
    Listing3dTour,
    SalesDemoFormPanel,
    BuyerExtendedContactForm,
    VeteransForm,
    CtaContainer,
    StreetViewContainer,
    TcpaContainer,
    DealScore,
    MissingHomeValueForm,
    AiSearch,
    SearchChat,
    DonutChart,
    NewRealCashForm,
    PoiPageSearch,
    PoiPropertiesMap,
    TermsConsentDialog,
    CashOfferMonthlyAlertCta,
    MonthlyAlertForm,
    SellingStepper,
    PublicRecordsSearchAutocomplete,
    MapMarker,
    CarouselContainer,
    MarqueeSlider,
    SellNowFormWithHomeValueNew,
    AgentProfileFormStepper,
    AgentVerificationStepper,
    AgentOnboardingStepper,
    QrCodeGenerator,
    HomeValueFormV2,
    AgentDashboardContainer,
    AgentLoginForm,
    AgentProfileClaimStepper,
    AgentRecoveryForm,
    AgentRegistrationForm,
    AgentPageSearchDirectory,
    PhoneVerification,
    EmailVerification,
    MarketSearch,
    MarketSearchFormStepper,
    AgentSearchAndClaimStepper,
    ListingViewStats,
    BellhopContainer,
    AnimationContainer,
    MarketSearchOnLoadCta,
    AgentAccountUpgradeForm,
    MarketSearchAgentCards,
    ConfettiContainer,
    AgentReviewForm,
    RealCashVerificationForm,
    FormFreeQualifiedForm,
    EstimatedMortgage,
    MortgageSearchFormStepper,
    MortgageSearchFormStepperTwo,
    MortgageSearchFormStepperThree,
    AgentCardWithMessage,
    SendgridTemplateExporter,
    Autocomplete,
    LeadsAverageChart,
    ShareUrl,
    StreetView,
    MultiStepperScheduleDemoFrom,
    VideoEmbed,
    Vimeo,
    YouTube,
    InterestCardV2,
    InterestAdviceCardV2
};
// register globally
Object.keys(components).forEach((name) => {
    Vue.component(name, components[name]);
});
